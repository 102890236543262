import { useEffect, useRef, useState } from "react";
import { Editor, Monaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import { PyodideInterface, loadPyodide } from "pyodide";
import { PyodideStatusTypes } from "../../types/pyodideStatusTypes";

interface IPyodideEditor {
  resetToggle?: boolean;
  toggleCompile: boolean;
  onCompileEnd: (value: string) => void;
  defaultCode?: string;
  setCode?: React.Dispatch<React.SetStateAction<string>>;
}

const PyodideEditor: React.FC<IPyodideEditor> = ({ toggleCompile, onCompileEnd, resetToggle, setCode, defaultCode = "" }) => {
  const [userCode, setUserCode] = useState<string>(``);

  const [pyodideStatusType, setPyodideStatusType] = useState<PyodideStatusTypes>(PyodideStatusTypes.Loading);
  const [pyodideAPI, setPyodideAPI] = useState<PyodideInterface>();

  const logs = useRef<string>("");

  useEffect(() => {
    loadPyodide({
      indexURL: "/static/pyodide/",
      stdout: (msg: string) => {
        logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + msg;
      },
      stderr: (msg: string) => {
        logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + msg;
      },
    })
      .then((value: PyodideInterface) => {
        setPyodideAPI(value);
        setPyodideStatusType(PyodideStatusTypes.Successfull);
      })
      .catch(() => setPyodideStatusType(PyodideStatusTypes.Failed));
  }, []);

  useEffect(() => {
    if (toggleCompile) {
      compileCode();
    }
  }, [toggleCompile]);

  useEffect(() => {
    if (resetToggle) {
      setUserCode(defaultCode ?? "");
    }
  }, [resetToggle]);

  useEffect(() => {
    setUserCode(defaultCode);
  }, [defaultCode]);

  const sendOutputToParent = () => {
    onCompileEnd(logs.current);
    logs.current = "";
  };

  const compileCode = () => {
    if (userCode === "" || pyodideAPI === undefined || pyodideStatusType !== PyodideStatusTypes.Successfull) {
      return;
    }

    try {
      pyodideAPI.runPython(userCode);
    } catch (error: any) {
      logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + error.message;
    } finally {
      sendOutputToParent();
    }
  };

  const handleEditorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) => {
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs-dark", // Or 'vs' for a light theme
      inherit: true,
      rules: [{ token: "", background: "#051B2C" }],
      colors: {
        "editor.background": "#051B2C",
        "editor.lineHighlightBackground": "#00000000",
        "editor.lineHighlightBorder": "#00000000",
      },
    });
    monaco.editor.setTheme("myCustomTheme");
  };

  return (
    <Editor
      options={{ fontSize: 16 }}
      height="300px"
      width="100%"
      theme={"vs-dark"}
      language={"python"}
      value={userCode}
      defaultValue={defaultCode}
      onChange={(value) => {
        setUserCode(value ?? "");

        if (setCode) {
          setCode(value ?? "");
        }
      }}
      onMount={handleEditorDidMount}
    />
  );
};

export default PyodideEditor;
